const popups = [
  {
    timestamp: 100,
    action: "createTextPopUp-for",
    repeat: 25,
    duration: 3000,
    style: "style2",
    left: -10,
    top: 10,
    leftOffset: 5,
    topOffset: 8,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    text: "connecting...",
    completed: false,
  },

  // {timestamp:300, action:"createTextPopUp", repeat:0, duration: 3000, style: "style1", left: 15, top: 35, text:"i worked really hard for this", completed: false},

  {
    timestamp: 400,
    action: "createTextPopUp",
    repeat: 25,
    duration: 3000,
    style: "style3",
    left: 10,
    top: 10,
    leftOffset: 5,
    topOffset: 8,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    text: "// fileID: SC-12082125-US-M-C",
    completed: false,
  },

  {
    timestamp: 800,
    action: "createTextPopUp",
    repeat: 25,
    duration: 3000,
    style: "style3",
    left: 20,
    top: 40,
    leftOffset: 5,
    topOffset: 8,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    text: "// running pathways analysis ...",
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 1400,
    action: "createTextPopUp",
    repeat: 0,
    duration: 3000,
    style: "style1",
    left: 15,
    top: 35,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "i worked really hard for this",
    completed: false,
  },

  {
    timestamp: 2350,
    action: "createTextPopUp",
    repeat: 0,
    duration: 3000,
    style: "style1",
    left: 15,
    top: 20,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "altered",
    completed: false,
  },

  {
    timestamp: 2450,
    action: "createTextPopUp",
    repeat: 0,
    duration: 3000,
    style: "style1",
    left: 20,
    top: 25,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "authored",
    completed: false,
  },

  {
    timestamp: 2550,
    action: "createTextPopUp",
    repeat: 0,
    duration: 3000,
    style: "style2",
    left: 25,
    top: 30,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "reforged",
    completed: false,
  },

  {
    timestamp: 2650,
    action: "createTextPopUp",
    repeat: 0,
    duration: 3000,
    style: "style1",
    left: 30,
    top: 35,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "sculpted?",
    completed: false,
  },

  {
    timestamp: 2750,
    action: "createTextPopUp-for",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 30,
    top: 35,
    text: "sculpted?",
    functionLeft: "*",
    functionTop: "%10",
    leftOffset: 0,
    topOffset: 10,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 4700,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style2",
    left: 30,
    top: 35,
    text: "?",
    leftOffset: 0,
    topOffset: 10,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 5500,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 45,
    top: 10,
    leftOffset: 0,
    topOffset: 0,
    delay: 0,
    text: "when your body doesn't feel age",
    completed: false,
  },

  {
    timestamp: 5700,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style2",
    left: -5,
    top: 30,
    text: "?",
    leftOffset: 5,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 5850,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 45,
    top: 15,
    text: "when your body needs nothing",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 6100,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 45,
    top: 20,
    text: "does that really have any meaning",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 6450,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 35,
    top: 70,
    text: "does sacrifice matter if it doesn't have a cost?",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 6800,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style2",
    left: -5,
    top: 70,
    text: "//then it's not a sacrifice",
    leftOffset: 10,
    topOffset: 3,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 7300,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 20,
    top: 70,
    text: "// pathways: OK",
    leftOffset: 10,
    topOffset: 3,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 7700,
    action: "createTextPopUp",
    repeat: 10,
    duration: 1000,
    style: "style3",
    left: 20,
    top: 70,
    text: "// running data files analysis ...",
    leftOffset: 10,
    topOffset: 3,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 8400,
    action: "createTextPopUp",
    repeat: 10,
    duration: 2000,
    style: "style2",
    left: 45,
    top: 70,
    text: "//nonsense",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 8750,
    action: "createTextPopUp",
    repeat: 10,
    duration: 4000,
    style: "style1",
    left: 60,
    top: 20,
    text: "do you know where i am",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 9650,
    action: "createTextPopUp",
    repeat: 10,
    duration: 4000,
    style: "style1",
    left: 30,
    top: 70,
    text: "cursed?",
    leftOffset: 5,
    topOffset: 5,
    delay: 0,
    completed: false,
  },

  {
    timestamp: 9800,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style2",
    left: -5,
    top: 20,
    text: "XXXXXXXXXXXXXXXXXXXXXXXX",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 9900,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 25,
    top: 75,
    text: "when bad reality happens to your circuits",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 10150,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 25,
    top: 80,
    text: "and your flesh thinks you're ugly",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 12450,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 30,
    top: 40,
    text: "how did you get here",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 12750,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style1",
    left: -5,
    top: 30,
    text: "hello",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 13150,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 60,
    top: 10,
    text: "i'm not quite feeling put together",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 13350,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 60,
    top: 30,
    text: "a lot of things are starting to disconnect",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 13900,
    action: "createTextPopUp-for",
    repeat: 10,
    duration: 1000,
    style: "style1",
    left: -5,
    top: 30,
    text: ".",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 14000,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 20,
    top: 60,
    text: "i feel like i chose this(?)",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 14300,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 20,
    top: 65,
    text: "like all the choices were already made for me",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 14700,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style2",
    left: 20,
    top: 65,
    text: "//there were no choices to begin with",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 14800,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 1000,
    style: "style1",
    left: -5,
    top: 30,
    text: "hey",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 14950,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 20,
    top: 85,
    text: "if you'd choose between having an identity and not being in pain",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 15700,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 85,
    text: "// data files: OK",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false
  },

  {
    timestamp: 16100,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 85,
    text: "// running neural analysis . . .",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 16700,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 25,
    top: 20,
    text: "what do you call an epiphany you never wanted to have?",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 17900,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 20,
    text: "// found values in hippocampus outside of allowed range",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 18300,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 20,
    text: "// patching ...",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 18750,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 15,
    top: 70,
    text: "am i not worth a rewatch",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 19600,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 20,
    text: "// patching 10% ...",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 20400,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 25,
    top: 20,
    text: "// patching 30% ...",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 21000,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: 25,
    top: 70,
    text: "after they mirror your nervous system you get complete control",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 23200,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 35,
    top: 70,
    text: "// patching 60% ...",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 24200,
    action: "createTextPopUp",
    repeat: 10,
    duration: 3000,
    style: "style3",
    left: 35,
    top: 70,
    text: "// patching error",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 24600,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 3000,
    style: "style3",
    left: -3,
    top: 55,
    text: "$deploy unit --force",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  {
    timestamp: 25000,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 3000,
    style: "style3",
    left: -5,
    top: 7,
    text: "// pathways error",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 25250,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 3000,
    style: "style3",
    left: -7,
    top: 25,
    text: "// data files error",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 25500,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 3000,
    style: "style3",
    left: -3,
    top: 55,
    text: "// neural data error",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },

  // 4:24
  {
    timestamp: 25700,
    action: "createTextPopUp-for",
    repeat: 10,
    duration: 3000,
    style: "style1",
    left: -5,
    top: 50,
    text: "in the end i'm taking away everything else that is not a part of the plan",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%10",
    delay: 0,
    completed: false,
  },

  {
    timestamp: 26100,
    action: "createTextPopUp-for",
    repeat: 20,
    duration: 3000,
    style: "style3",
    left: -3,
    top: 55,
    text: "// deploying unit",
    leftOffset: 6,
    topOffset: 5,
    functionLeft: "*",
    functionTop: "%5",
    delay: 0,
    completed: false,
    flickerEyes: true
  },
];

export default popups;

